<template>
  <el-dialog
    title="添加建模"
    width="30%"
    v-bind="$attrs"
    @close="doClose">
    <el-form :model="form" :rules="rules" ref="form" label-position="top">
      <el-form-item label="3d建模视频" prop="fileUrl">
        <el-upload
          ref="uploader"
          accept="video/*"
          :headers="{Authorization:token}"
          action="http://jaxf.jjxsw.vip:9002/app-version/uploadFileAlias"
          :limit="1"
          :on-success="setFileUrl"
          :on-exceed="onExceed"
          list-type="text">
          <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
    <div style="display: flex;justify-content: center">
      <el-button size="small" @click="doClose">取 消</el-button>
      <el-button size="small" type="primary" @click="submit">保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>

export default {
  name: "InnerDialog",
  inheritAttrs: false,
  props: {
    buildingId: Number,
  },
  data() {
    return {
      form: {
        fileUrl: ""
      },
      rules: {
        fileUrl: [
          {required: true, message: '请上传3d建模视频', trigger: 'change'}
        ],
      },
    }
  },
  computed: {
    token() {
      return sessionStorage.getItem('token')
    },
  },
  methods: {
    submit() {
      this.$refs.form.validate(valid => {
        const fd=new FormData()
        for(const k in this.form){
          fd.append(k,this.form[k])
        }
        fd.append("buildingId",this.buildingId)
        if (valid) {
          this.$axios({
            url: "/building/add3dFile",
            method: "post",
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: fd

          }).then(() => {
            this.doClose()
            this.resetForm()
            this.$emit('change') //通知父组件已经添加完毕，更新列表数据
          })
        }
      })
    },
    setFileUrl(res) {
      this.form.fileUrl = res.data
    },
    onExceed() {
      this.$message.warning("最多上传一张图片")
    },
    /**
     * 关闭弹窗
     */
    doClose() {
      this.$emit('update:visible', false)
      this.resetForm()
    },
    resetForm() {
      this.form = {
        fileUrl: ""
      }
      this.$refs.form.resetFields()
      this.$refs.uploader.clearFiles()
    }
  }
}
</script>
