<template>
  <div class="container" v-loading="loading1" element-loading-text="数据导出中" element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="header">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item>
          <el-popover placement="right" width="800" v-model="searchShow">
            <el-form :inline="true" :model="searchForm" class="demo-form-inline">
              <el-form-item label="建筑物名称">
                <el-input clearable size="small" v-model="searchForm.buildingName" placeholder="请输入建筑物名称"
                          style="width:150px"></el-input>
              </el-form-item>
              <el-form-item label="建筑物录入时间">
                <el-date-picker v-model="searchForm.createTime" type="date" size="small" placeholder="选择日期" clearable
                                style="width:150px"></el-date-picker>
              </el-form-item>
              <el-form-item label="行政区域">
                <el-cascader v-model="areaCodes" size="small" placeholder="请选择" :options="areaList"
                             :props="{ checkStrictly: true }" clearable style="width:150px"></el-cascader>
              </el-form-item>
              <el-form-item label="时间">
                <el-date-picker v-model="searchForm.date" type="daterange" range-separator="至" start-placeholder="开始日期"
                                end-placeholder="结束日期" size="small">
                </el-date-picker>
              </el-form-item>
            </el-form>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" @click="searchShow = false">取消</el-button>
              <el-button type="primary" size="mini" @click="initTable">查询</el-button>
            </div>
            <el-button type="primary" size="small" slot="reference" icon="el-icon-search">查询</el-button>
          </el-popover>
        </el-form-item>
        <el-form-item>
          <el-button type="success" size="small" icon="el-icon-plus" @click="open('add')" v-role="'building:add'">
            新增建筑物
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="warning" size="small" icon="el-icon-download" @click="exportExcel">导出建筑物</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="buildlingList" stripe style="width: 100%" height="100%" v-loading="loading">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-row :gutter="20">
              <el-col :span="12" :offset="0">
                <el-form label-position="left" inline class="demo-table-expand">
                  <el-form-item label="建筑物名称" :label-width="formLabelWidth">
                    <span>{{ props.row.buildingName }}</span>
                  </el-form-item>
                  <el-form-item label="地址" :label-width="formLabelWidth">
                    <span>{{ props.row.address }}</span>
                  </el-form-item>
                  <el-form-item label="行政区域" :label-width="formLabelWidth">
                    <span>{{ props.row.areaName }}</span>
                  </el-form-item>
                  <el-form-item label="面积" :label-width="formLabelWidth">
                    <span>{{ props.row.area }}</span>
                  </el-form-item>
                  <el-form-item label="楼层数" :label-width="formLabelWidth">
                    <span>{{ props.row.floorNum }}</span>
                  </el-form-item>
                  <el-form-item label="耐火等级" :label-width="formLabelWidth">
                    <span>{{ props.row.fireResistLevelName }}</span>
                  </el-form-item>
                  <el-form-item label="火灾危险性" :label-width="formLabelWidth">
                    <span>{{ props.row.fireDangerName }}</span>
                  </el-form-item>
                  <el-form-item label="是否为高层建筑" :label-width="formLabelWidth">
                    <span>{{ props.row.isTall === 1 ? '是' : '否' }}</span>
                  </el-form-item>
                  <el-form-item label="楼栋长" :label-width="formLabelWidth">
                    <span>{{ props.row.managerName }}</span>
                  </el-form-item>
                  <el-form-item label="楼栋长电话" :label-width="formLabelWidth">
                    <span>{{ props.row.managerPhone }}</span>
                  </el-form-item>
                </el-form>
              </el-col>
              <el-col :span="12" :offset="0">
                <el-form label-position="left" inline class="demo-table-expand">
                  <el-form-item label="uuid" :label-width="formLabelWidth">
                    <span>{{ props.row.uuid }}</span>
                  </el-form-item>
                  <el-form-item label="建筑物性质" :label-width="formLabelWidth">
                    <span>{{ props.row.natureName }}</span>
                  </el-form-item>
                  <el-form-item label="建筑机构" :label-width="formLabelWidth">
                    <span>{{ props.row.structureName }}</span>
                  </el-form-item>
                  <el-form-item label="建筑类型" :label-width="formLabelWidth">
                    <span>{{ props.row.buildTypeName }}</span>
                  </el-form-item>
                  <el-form-item label="经纬度" :label-width="formLabelWidth">
                    <span>{{ props.row.lng }} # {{ props.row.lat }}</span>
                    <el-button type="text" size="default"
                               @click="open('location',{lng:props.row.lng,lat:props.row.lat})"
                               icon="el-icon-location"></el-button>
                  </el-form-item>
                  <el-form-item label="单元数" :label-width="formLabelWidth">
                    <span>{{ props.row.unitNum }}</span>
                  </el-form-item>
                  <el-form-item label="辖区民警" :label-width="formLabelWidth">
                    <span>{{ props.row.localPolice }}</span>
                  </el-form-item>
                  <el-form-item label="民警电话" :label-width="formLabelWidth">
                    <span>{{ props.row.policePhone }}</span>
                  </el-form-item>
                  <el-form-item label="备注" :label-width="formLabelWidth">
                    <span>{{ props.row.remark }}</span>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column prop="areaName" label="所属区域" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="buildingName" label="建筑物名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="address" label="地址" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="safeExit" label="安全出口数" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="floorNum" label="楼层数" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="managerName" label="楼栋长" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="managerPhone" label="楼栋长电话" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="policePhone" label="民警电话" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="sysFlag" label="同步状态" show-overflow-tooltip width="100">
          <template slot-scope="scope">
            <el-tag type="danger" size="small" v-show="scope.row.syncFlag!==1">未同步</el-tag>
            <el-tag type="success" size="small" v-show="scope.row.syncFlag===1">已同步</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-popover placement="top" trigger="click">
              <el-tooltip class="item" effect="dark" content="修改" placement="top">
                <el-button size="mini" @click="open('edit',scope.row)" type="warning" icon="el-icon-edit" circle
                           v-role="'building:edit'"></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="删除" placement="top">
                <el-button type="danger" @click="deleteBuilding(scope.row.id)" size="mini" icon="el-icon-delete-solid"
                           circle v-role="'building:del'"></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="建筑物同步" placement="top">
                <el-button type="success" @click="setBuildingSync(scope.row.id)" size="mini" icon="el-icon-refresh"
                           circle v-show="scope.row.syncFlag!==1"></el-button>
              </el-tooltip>

              <el-tooltip class="item" effect="dark" content="强制同步" placement="top">
                <el-button type="danger" @click="setBuildingSync(scope.row.id)" size="mini" icon="el-icon-refresh"
                           circle v-show="scope.row.syncFlag===1"></el-button>
              </el-tooltip>

              <el-tooltip class="item" effect="dark" content="查看楼层" placement="top">
                <el-button type="primary" size="mini" icon="el-icon-search" @click="open('floor',scope.row)"
                           circle></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="查看3d建模" placement="top">
                <el-button type="primary" size="mini" icon="el-icon-video-play" @click="open('3d',scope.row)"
                           circle></el-button>
              </el-tooltip>
              <el-button slot="reference" size="mini" type="primary" icon="el-icon-setting" circle></el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10"
                     layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- 建筑物弹窗 -->
    <el-dialog :title="optFlag?'添加建筑物':'修改建筑物'" :visible.sync="buildingShow" width="55%" @close="clear" top="5%"
               v-dialogDrag :close-on-click-modal="false">
      <el-form :model="buildingForm" ref="buildingForm" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="建筑物名称" prop="buildingName" :label-width="formLabelWidth2">
              <el-input clearable v-model="buildingForm.buildingName" placeholder="请输入建筑物名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="地址" prop="address" :label-width="formLabelWidth">
              <el-input clearable v-model="buildingForm.address" placeholder="请输入建筑物地址"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-row :gutter="20">
              <el-col :span="18" :offset="0">
                <el-form-item label="面积" prop="area" :label-width="formLabelWidth2">
                  <el-input clearable v-model.number="buildingForm.area" placeholder="请输入面积"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6" :offset="0">
                <el-form-item label="(平方米)">
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="行政区域" prop="areaCodes" :label-width="formLabelWidth">
              <el-cascader v-model="buildingForm.areaCodes" placeholder="请选择该机构的行政区域" :options="areaList"
                           :props="{ checkStrictly: true }" clearable style="width:100%"></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="楼层数" prop="floorNum" :label-width="formLabelWidth2">
              <el-input-number v-model="buildingForm.floorNum" :min="1" style="float:left">
              </el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="是否为高层建筑" prop="isTall" :label-width="formLabelWidth">
              <el-radio-group v-model="buildingForm.isTall">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="建筑物性质" prop="natureId" :label-width="formLabelWidth2">
              <el-select v-model="buildingForm.natureId" placeholder="请选择建筑物性质" style="float:left">
                <el-option v-for="(item,i) in buildingNatureList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="建筑结构" prop="structureId" :label-width="formLabelWidth">
              <el-select v-model="buildingForm.structureId" placeholder="请选择建筑结构" style="float:left">
                <el-option v-for="(item,i) in buildingStructureList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="建筑类型" prop="buildType" :label-width="formLabelWidth2">
              <el-select v-model="buildingForm.buildType" placeholder="请选择建筑类型" style="float:left">
                <el-option v-for="(item,i) in buildTypeList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="消防负责部门" :label-width="formLabelWidth">
              <el-autocomplete class="inline-input" v-model="value" value-key="unitName"
                               :fetch-suggestions="querySearch" placeholder="请输入内容" :trigger-on-focus="false"
                               @select="handleSelect" style="float:left"></el-autocomplete>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="楼栋长" prop="managerName" :label-width="formLabelWidth2">
              <el-input clearable v-model="buildingForm.managerName" placeholder="请输入楼栋长姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="楼栋长电话" prop="managerPhone" :label-width="formLabelWidth">
              <el-input clearable v-model="buildingForm.managerPhone" placeholder="请输入楼栋长电话" maxlength="11"
                        show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="辖区民警" prop="localPolice" :label-width="formLabelWidth2">
              <el-input clearable v-model="buildingForm.localPolice" placeholder="请输入辖区民警"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="民警电话" prop="policePhone" :label-width="formLabelWidth">
              <el-input clearable v-model="buildingForm.policePhone" placeholder="请输入民警电话" maxlength="11"
                        show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="耐火等级" prop="fireResistLevel" :label-width="formLabelWidth2">
              <el-select v-model="buildingForm.fireResistLevel" placeholder="请选择耐火等级" style="float:left">
                <el-option v-for="(item,i) in fireResistLevelList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="火灾危险性" prop="fireDanger" :label-width="formLabelWidth">
              <el-select v-model="buildingForm.fireDanger" placeholder="请选择火灾危险性" style="float:left">
                <el-option v-for="(item,i) in fireDangerList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="经纬度" :label-width="formLabelWidth2">
              <el-row :gutter="20">
                <el-col :span="9" :offset="0">
                  <el-form-item prop="lng">
                    <el-input clearable v-model.number="buildingForm.lng" placeholder="经度" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="1" :offset="0">
                  #
                </el-col>
                <el-col :span="9" :offset="0">
                  <el-form-item prop="lat">
                    <el-input clearable v-model.number="buildingForm.lat" placeholder="纬度" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="3" :offset="0" style="padding-left:0">
                  <el-form-item>
                    <el-tooltip class="item" effect="dark" content="定位" placement="top">
                      <el-button type="text" style="font-size:16px" size="default" @click="open('map')"
                                 icon="el-icon-location"></el-button>
                    </el-tooltip>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="单元数" prop="unitNum" :label-width="formLabelWidth">
              <el-input-number v-model="buildingForm.unitNum" :min="1" style="float:left">
              </el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="安全出口数" prop="safeExit" :label-width="formLabelWidth2">
              <el-input clearable v-model.number="buildingForm.safeExit" placeholder="请输入安全出口数"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="备注" prop="remark" :label-width="formLabelWidth2">
              <el-input clearable v-model="buildingForm.remark" placeholder="请输入备注"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialog-footer">
        <el-button size="small" @click="buildingShow = false">取 消</el-button>
        <el-button size="small" type="primary" @click="addBuilding" v-if="optFlag">添 加</el-button>
        <el-button size="small" type="primary" @click="updateBuilding" v-if="!optFlag">修 改</el-button>
      </div>
    </el-dialog>
    <!-- 地图弹窗 -->
    <el-dialog class="dialog" :visible.sync="mapShow" width="50%" top="5%">
      <div class="map">
        <baidu-map class="bmView" :scroll-wheel-zoom="true" :center="location" style="height:500px" :zoom="zoom"
                   ak="ahVb0clSVLITXPpNieRI7DXL47tgHvcE" @ready="handler" @click="setPosition">
          <bm-view style="width: 100%; height:100px; flex: 1"></bm-view>
          <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true"
                          :autoLocation="true"></bm-geolocation>
          <bm-marker :position="location" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
          </bm-marker>
        </baidu-map>
      </div>
      <div class="dialog-footer" v-show="optFlag2">
        <el-button size="small" @click="mapShow = false">取 消</el-button>
        <el-button size="small" type="primary" @click="getPosition">确 定</el-button>
      </div>
    </el-dialog>

    <!--楼层弹窗-->
    <floor-dialog :visible.sync="floorDialog.visible" :building-id="floorDialog.fetchId" />

    <building3d-dialog :visible.sync="building3dDialog.visible" :building-id="building3dDialog.fetchId"/>
  </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmView from 'vue-baidu-map/components/map/MapView.vue'
import BmGeolocation from 'vue-baidu-map/components/controls/Geolocation.vue'
import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue'
import _ from 'lodash'
import FloorDialog from "./components/FloorDialog";
import Building3dDialog from "./components/Building3dDialog"

export default {
  components: {
    FloorDialog,
    Building3dDialog,
    BaiduMap,
    BmView,
    BmGeolocation,
    BmMarker,
  },
  data() {
    return {
      areaCodes: [],
      value: '',
      location: {
        lng: 0,
        lat: 0
      },
      zoom: 18,
      addressKeyword: '',
      currentPage: 1,
      formLabelWidth: '120px',
      formLabelWidth2: '100px',
      total: 0,
      buildlingList: [],
      unitList: [],
      areaList: [], // 行政区域列表
      buildingId: 0, // 建筑物ID
      buildingForm: {
        buildingName: '',
        address: '',
        area: '',
        floorNum: 1,
        areaCodes: [],
        isTall: '',
        natureId: '',
        structureId: '',
        lng: '',
        lat: '',
        unitNum: 1,
        managerName: '',
        managerPhone: '',
        localPolice: '',
        policePhone: '',
        remark: ''
      }, // 建筑表单
      buildingFormCopy: {},
      buildingNatureList: [], // 建筑物性质
      buildingStructureList: [], // 建筑机构
      fireResistLevelList: [], // 耐火等级
      fireDangerList: [], // 火灾危险性
      buildTypeList: [], // 建筑类型
      rules: {
        // address: [
        //   { required: true, message: '请输入地址', trigger: 'blur' }
        // ],
        // area: [
        //   { required: true, message: '请输入面积', trigger: 'blur' }
        // ],
        buildingName: [
          {required: true, message: '请输入建筑物名称', trigger: 'blur'}
        ],
        // floorNum: [
        //   { required: true, message: '请输入楼层数' }
        // ],
        // isTall: [
        //   { required: true, message: '请选择是否为高层建筑', trigger: 'change' }
        // ],
        natureId: [
          {required: true, message: '请选择建筑物性质', trigger: 'change'}
        ],
        structureId: [
          {required: true, message: '请选择建筑结构', trigger: 'change'}
        ],
        buildType: [
          {required: true, message: '请选择建筑类型', trigger: 'change'}
        ],
        fireResistLevel: [
          {required: true, message: '请选择耐火等级', trigger: 'change'}
        ],
        fireDanger: [
          {required: true, message: '请选择火灾危险性', trigger: 'change'}
        ],
        safeExit: [
          {required: true, message: '请输入安全出口数', trigger: 'blur'}
        ]
        // unitNum: [
        //   { required: true, message: '请输入建筑物名称' }
        // ],
        // managerName: [
        //   { required: true, message: '请输入楼栋长', trigger: 'blur' }
        // ],
        // managerPhone: [
        //   { required: true, message: '请输入楼栋长电话', trigger: 'blur' },
        //   { pattern: /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/, message: '格式不正确', trigger: 'blur' }
        // ],
        // localPolice: [
        //   { required: true, message: '请输入辖区民警', trigger: 'blur' }
        // ],
        // policePhone: [
        //   { required: true, message: '请输入民警电话', trigger: 'blur' },
        //   { pattern: /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/, message: '格式不正确', trigger: 'blur' }
        // ]
      },
      searchForm: {
        date: []
      },
      buildingShow: false,
      mapShow: false,
      optFlag: false,
      optFlag2: false,
      loading: false,
      loading1: false,
      searchShow: false,
      floorDialog: {//楼层 dialog
        visible: false,
        fetchId: 0,
      },
      building3dDialog: {
        visible: false,
        fetchId: 0
      }
    }
  },
  created() {
    this.initTable()
    this.getAreaList()
    this.getBuildingNatureList()
    this.getBuildingStructureList()
    this.getUnitList()
    this.getFireResistLevelList()
    this.getFireDangerList()
    this.getBuildTypeList()
    this.buildingFormCopy = JSON.stringify(this.buildingForm)
  },
  methods: {
    fetStoreyData() {

    },
    async handleCurrentChange(val) {
      this.currentPage = val
      this.initTable()
    },
    // 初始化建筑物表格
    async initTable() {
      const data = {}
      if (this.searchForm.buildingName) {
        data.buildingName = this.searchForm.buildingName
      }
      if (this.searchForm.createTime) {
        data.createTime = this.searchForm.createTime
      }
      if (this.areaCodes.length > 0) {
        data.areaCode = this.areaCodes[this.areaCodes.length - 1]
      }
      if (this.searchForm.date) {
        if (this.searchForm.date.length > 0) {
          data.startTime = this.searchForm.date[0]
          data.endTime = this.searchForm.date[1]
        }
      }
      data.pageNo = this.currentPage
      data.pageSize = 10

      this.loading = true

      const {data: result} = await this.$axios.get('/building/list', {params: data})
      if (result.code === 200) {
        this.loading = false
        this.buildlingList = result.data.result
        this.total = result.data.total
      }
      else {
        this.loading = false
        this.$message.error(result.msg)
      }
    },
    // 删除某条数据
    async deleteBuilding(id) {
      const confirm = await this.$confirm('此操作将永久删除该建筑物, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const {data: result} = await this.$axios.delete(`/building/del/${id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          const totalPage = Math.ceil((this.total - 1) / 10) // 总页数
          this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
          this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
          this.initTable()
        }
        else {
          this.$message.error(result.msg)
        }
      }
    },
    // 修改某条数据
    updateBuilding: _.debounce(function () {
      this.$refs.buildingForm.validate(async valid => {
        if (valid) {
          if (this.buildingForm.areaCodes.length > 0) {
            this.buildingForm.areaCode = this.buildingForm.areaCodes[this.buildingForm.areaCodes.length - 1]
          }
          else {
            this.$message.error('请选择该机构所在行政区域')
            return
          }
          if (!this.buildingForm.unitId) {
            this.$message.error('请选择消防负责部门')
          }
          const data = this.buildingForm.areaCodes
          delete this.buildingForm.areaCodes
          const {data: result} = await this.$axios.put(`/building/edit/${this.buildingId}`, this.buildingForm)
          if (result.code === 200) {
            this.$message.success('修改成功')
            this.buildingShow = false
            this.initTable()
          }
          else {
            this.buildingForm.areaCodes = data
            this.$message.error(result.msg)
          }
        }
      })
    }, 1000),
    // 新增培训
    addBuilding: _.debounce(function () {
      this.$refs.buildingForm.validate(async valid => {
        if (valid) {
          if (this.buildingForm.areaCodes.length > 0) {
            this.buildingForm.areaCode = this.buildingForm.areaCodes[this.buildingForm.areaCodes.length - 1]
          }
          else {
            this.$message.error('请选择该机构所在行政区域')
            return
          }
          if (!this.buildingForm.unitId) {
            this.$message.error('请选择消防负责部门')
          }
          const data = this.buildingForm.areaCodes
          delete this.buildingForm.areaCodes
          const {data: result} = await this.$axios.post('/building/add', this.buildingForm)
          if (result.code === 200) {
            this.$message.success('新增成功')
            const totalPage = Math.ceil((this.total + 1) / 10) // 总页数
            this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
            this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
            this.buildingShow = false
            this.initTable()
          }
          else {
            this.buildingForm.areaCodes = data
            this.$message.error(result.msg)
          }
        }
      })
    }, 1000),
    // 获取位置
    async getPosition() {
      console.log(this.location)
      this.buildingForm.lng = this.location.lng
      this.buildingForm.lat = this.location.lat
      this.mapShow = false
    },
    // 设定位置
    async setPosition(e) {
      if (this.optFlag2) {
        this.location.lng = e.point.lng
        this.location.lat = e.point.lat
      }
    },
    async setBuildingSync(id) {
      const confirm = await this.$confirm('是否同步该建筑物至省平台?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const {data: result} = await this.$axios.post(`/pBuilding/add/${id}`)
        if (result.code === 200) {
          this.initTable()
          this.$message.success('同步成功')
        }
        else {
          this.$message.error(result.msg)
        }
      }
    },
    // 地图初始化
    handler({BMap, map}) {
      const that = this
      if (this.optFlag2) {
        var geolocation = new BMap.Geolocation()
        geolocation.getCurrentPosition(function (r) {
          that.location.lng = r.point.lng
          that.location.lat = r.point.lat
        })
      }
    },
    open(flag, row) {
      switch (flag) {
        case 'add':
          this.buildingShow = true
          this.$nextTick(() => {
            this.$refs.buildingForm.clearValidate()
          })
          this.optFlag = true
          break
        case 'edit':
          this.buildingShow = true
          this.optFlag = false
          this.$nextTick(() => {
            this.$refs.buildingForm.clearValidate()
          })
          this.buildingForm = JSON.parse(JSON.stringify(row))
          if (!this.buildingForm.natureId) {
            this.$set(this.buildingForm, 'natureId', '')
          }
          if (!this.buildingForm.structureId) {
            this.$set(this.buildingForm, 'structureId', '')
          }
          this.buildingId = row.id
          break
        case 'map':
          this.mapShow = true
          this.optFlag2 = true
          break
        case 'location':
          this.mapShow = true
          this.optFlag2 = false
          this.location = row
          break
        case 'floor':
          this.floorDialog.visible = true
          this.floorDialog.fetchId = row.id
          break
        case '3d':
          this.building3dDialog.visible = true
          this.building3dDialog.fetchId = row.id
          break
      }
    },
    // 查找行政区域
    async getAreaList() {
      const {data: result} = await this.$axios.get('/area/listByUser')
      if (result.code === 200) {
        this.areaList = this.changeKey(result.data)
      }
      else {
        this.$message.error(result.msg)
      }
    },
    // 查找建筑物性质
    async getBuildingNatureList() {
      const {data: result} = await this.$axios.get('/dict/list', {params: {dictkey: 'buildingNature'}})
      if (result.code === 200) {
        this.buildingNatureList = result.data
      }
    },
    // 查找建筑结构
    async getBuildingStructureList() {
      const {data: result} = await this.$axios.get('/dict/list', {params: {dictkey: 'buildingStructure'}})
      if (result.code === 200) {
        this.buildingStructureList = result.data
      }
    },
    // 查找建筑结构
    async getBuildTypeList() {
      const {data: result} = await this.$axios.get('/dict/list', {params: {dictkey: 'buildType'}})
      if (result.code === 200) {
        this.buildTypeList = result.data
      }
    },
    // 查找耐火等级
    async getFireResistLevelList() {
      const {data: result} = await this.$axios.get('/dict/list', {params: {dictkey: 'fireResistLevel'}})
      if (result.code === 200) {
        this.fireResistLevelList = result.data
      }
    },
    // 查找耐火等级
    async getFireDangerList() {
      const {data: result} = await this.$axios.get('/dict/list', {params: {dictkey: 'fireDanger'}})
      if (result.code === 200) {
        this.fireDangerList = result.data
      }
    },
    // 查找建筑结构
    async getUnitList() {
      const {data: result} = await this.$axios.get('/unit/listAllUnit')
      if (result.code === 200) {
        this.unitList = result.data
      }
    },
    changeKey(array) {
      array.forEach((item, index) => {
        [item.value, item.areaCode] = [item.areaCode, item.value]
        if (item.children.length > 0) {
          item.children.forEach((subItem, index2) => {
            [subItem.value, subItem.areaCode] = [subItem.areaCode, subItem.value]
            if (subItem.children) {
              if (subItem.children.length > 0) {
                subItem.children.forEach((subItem2, index) => {
                  [subItem2.value, subItem2.areaCode] = [subItem2.areaCode, subItem2.value]
                })
              }
            }
          })
        }
      })
      return array
    },
    // 导出Excel
    async exportExcel() {
      this.loading1 = true
      const data = {}
      if (this.searchForm.buildingName) {
        data.buildingName = this.searchForm.buildingName
      }
      if (this.searchForm.createTime) {
        data.createTime = this.searchForm.createTime
      }
      if (this.searchForm.date) {
        if (this.searchForm.date.length > 0) {
          data.startTime = this.searchForm.date[0]
          data.endTime = this.searchForm.date[1]
        }
      }
      const result = await this.$axios.post('/util/exportBuilding', data, {responseType: 'blob'})
      if (result.data.type === 'application/json') {
        this.loading1 = false
        this.$message.error('数据为空,不能导出')
        return
      }
      if (!result.data.message) {
        this.loading1 = false
      }
      else {
        this.loading1 = false
        this.$message.error(result.data.message)
        return
      }
      const blob = new Blob([result.data], {type: 'application/octet-stream'})
      if ('download' in document.createElement('a')) {
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) // 创建下载的链接
        downloadElement.href = href
        downloadElement.download = '建筑物信息.xlsx' // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
      }
      else {
        navigator.msSaveBlob(blob, '建筑物信息.xlsx')
      }
    },
    // 关闭弹窗清空表单
    clear() {
      this.buildingForm = JSON.parse(this.buildingFormCopy)
      // this.$refs.buildingForm.resetFileds()
      this.buildingForm = {
        buildingName: '',
        address: '',
        area: '',
        floorNum: 1,
        areaCodes: [],
        isTall: '',
        natureId: '',
        structureId: '',
        lng: '',
        lat: '',
        unitNum: 1,
        managerName: '',
        managerPhone: '',
        localPolice: '',
        remark: '',
        unitId: ''
      }
      this.$refs.buildingForm.clearValidate()
    },
    querySearch(queryString, cb) {
      const unitList = this.unitList
      var results = queryString ? unitList.filter(this.createFilter(queryString)) : unitList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return (state) => {
        return (state.unitName.indexOf(queryString) === 0)
      }
    },
    handleSelect(item) {
      console.log(item)
      this.$set(this.buildingForm, 'unitId', item.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-image {
  width: 150px;
  height: 150px;
}

.map {
  width: 100%;
  height: 500px !important;

  .bmView {
    height: 100% !important;

    > div:first-child {
      height: 500px !important;
    }
  }
}

.dialog {
  margin: 0;
  padding: 10px;

  ::v-deep .el-dialog__header {
    display: none !important;
  }
}

.demo-table-expand {
  font-size: 0;

  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;

    ::v-deep label {
      color: #99a9bf !important;
    }
  }
}

::v-deep .el-table__expanded-cell {
  padding: 0 50px;
}

//::v-deep
</style>
