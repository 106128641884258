<template>
  <el-dialog
    :title="type==='add'?'新增楼层':'更新楼层'"
    width="30%"
    v-bind="$attrs"
    @close="doClose">
    <el-form :model="form" :rules="rules" ref="form" label-position="top">
      <el-form-item label="楼层名" prop="floorName">
        <el-input clearable v-model="form.floorName" placeholder="请输入楼层名称"></el-input>
      </el-form-item>
      <el-form-item label="2d设计图文件" prop="fileUrl">
        <el-upload
          ref="uploader"
          :file-list="fileList"
          :headers="{Authorization:token}"
          action="http://jaxf.jjxsw.vip:9002/app-version/uploadFileAlias"
          :limit="1"
          :on-success="setFileUrl"
          :on-exceed="onExceed"
          list-type="picture-card">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
    </el-form>
    <div style="display: flex;justify-content: center">
      <el-button size="small" @click="doClose">取 消</el-button>
      <el-button size="small" type="primary" @click="submit">保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "InnerDialog",
  inheritAttrs: false,
  props: {
    type: String,
    buildingId: [String, Number],
    row: Object
  },
  watch: {
    row(v) {
      if (v != null) {
        Object.assign(this.form, v)
        this.$forceUpdate()
      }
    }
  },
  data() {
    return {
      form: {
        floorName: "",
        fileUrl: ""
      },
      rules: {
        floorName: [
          {required: true, message: '楼层名不能为空'}
        ],
        fileUrl: [
          {required: true, message: '请上传2d设计图文件', trigger: 'change'}
        ],
      },
    }
  },
  computed: {
    token() {
      return sessionStorage.getItem('token')
    },
    fileList() {
      if (this.type === 'add') return []
      return [{url: this.row.fileUrl}]
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let reqUrl = '/building/addFloor'
          if (this.type === 'edit') {
            reqUrl = '/building/editFloor'
          }
          this.$axios.post(reqUrl, {...this.form, buildingId: this.buildingId}).then(() => {
            this.doClose()
            this.resetForm()
            this.$emit('change') //通知父组件已经添加完毕，更新列表数据
          })
        }
      })
    },
    setFileUrl(res) {
      this.form.fileUrl = res.data
    },
    onExceed() {
      this.$message.warning("最多上传一张图片")
    },
    /**
     * 关闭弹窗
     */
    doClose() {
      this.$emit('update:visible', false)
      this.resetForm()
    },
    resetForm() {
      this.form = {
        floorName: "",
        fileUrl: ""
      }
      this.$refs.form.resetFields()
      this.$refs.uploader.clearFiles()
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-upload-list__item {
  transition: none !important;
}
</style>
