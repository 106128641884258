<template>
  <el-dialog
    title="3D建模"
    width="70%"
    top="4%"
    v-bind="$attrs"
    @close="handleClose"
    @closed="handleClosed">
    <div class="header">
      <el-button
        type="success"
        size="small"
        icon="el-icon-plus"
        @click="openInnerDialog('add')">
        新增建模
      </el-button>
      <inner-dialog
        append-to-body
        :building-id="buildingId"
        :visible.sync="innerDialog.visible"
        @change="fetchData" />
    </div>
    <el-row type="flex" justify="space-between" class="content">
      <el-col :span="6" style="height: 100%">
        <el-table
          :border="false"
          :data="list"
          stripe
          style="height:450px;overflow-y: scroll"
          v-loading="isLoading"
          :row-class-name="highlightCurrRow"
          @row-click="handleTableRowClick">
          <el-table-column label="名称" align="center" :formatter="formatterIndex" />
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" icon="el-icon-delete-solid"
                         circle @click.stop.prevent="del3dBuilding(scope.row)"></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="18">
        <div v-if="isNoSelect" class="img-backup-wrap">
          <span>请选择一个建模查看</span>
        </div>
        <video
          v-else
          :key="activeId"
          autoplay
          loop
          style="width: 100%;height: 450px"
          controls="controls"
          :src="activeFileUrl"></video>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import InnerDialog from "./InnerDialog";

export default {
  name: "Building3dDialog",
  components: {InnerDialog},
  inheritAttrs: false,
  props: {
    buildingId: [String, Number]
  },
  data() {
    return {
      isLoading: false,
      innerDialog: {
        visible: false,
      },
      list: [],
      activeId: null
    }
  },
  computed: {
    isNoSelect() {
      return this.activeFileUrl === ''
    },
    activeFileUrl() {
      if (this.activeId == null) return ""
      const activeFloor = this.list.find(v => v.id === this.activeId)
      if (activeFloor === undefined) return ""
      return activeFloor.file_url
    }
  },
  watch: {
    '$attrs.visible': function (v) {
      if (this.buildingId !== 0 && v) this.fetchData()
    }
  },
  methods: {
    //加载楼层列表
    async fetchData() {
      this.isLoading = true
      const {data: result} = await this.$axios("/building/3dFileList/" + this.buildingId)
      this.list = result.data
      this.$nextTick(() => {
        this.isLoading = false
      })
    },
    //开启内部弹窗
    openInnerDialog(type, row) {
      this.innerDialog.type = type
      if (type === 'edit') this.innerDialog.row = {...row}
      this.innerDialog.visible = true
    },
    //关闭弹窗
    handleClose() {
      this.$emit('update:visible', false)
    },
    //关闭弹窗后
    handleClosed() {
      this.list = []
    },
    //点击表格行
    handleTableRowClick(row) {
      this.activeId = row.id
    },
    highlightCurrRow({row}) {
      if (row.id === this.activeId)
        return 'table-row-active'
      return ''
    },
    formatterIndex(row, column, cellValue, index) {
      return '3D建模' + (index + 1)
    },
    //删除楼层
    del3dBuilding(row) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.delete("/building/delete3dFile/" + row.id).then(() => {
          this.list = this.list.filter(v => v.id !== row.id)
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-loading-mask {
  background-color: transparent !important;
}

.img-backup-wrap {
  background-color: #ccc;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  font-size: 20px;
}

::v-deep .el-table .table-row-active {
  background-color: rgba(28, 97, 160, 1) !important;
}

::v-deep .el-table:before {
  height: 0 !important;
}
</style>
